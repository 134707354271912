import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Navigation from '../navigation';
import { transform } from '../../utils/cloudinary';

const organizationQuery = graphql`
  {
    organization: sanityInfo {
      name
      logo {
        secure_url
      }
    }
  }
`;

const Header = ({ root }) => {
  const data = useStaticQuery(organizationQuery);
  const photo = transform(data.organization.logo.secure_url)
    .width('320')
    .height('320')
    .quality('auto:good')
    .format('auto')
    .toString();

  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container d-flex align-items-center justify-content-between">

        <div className="logo">
          {/* <h1 className="text-light"><a href="/"><span>You Are Loved</span></a></h1> */}
          {/* Uncomment below if you prefer to use an image logo */}
          {/* <a href="/"><img src="assets/img/logo.png" alt="You are Loved" className="img-fluid" /></a> */}
          {/* <a href="/"><StaticImage src="../../images/logo.png" alt="You are Loved" className="img-fluid" /></a> */}
          <a href="/#hero"><img src={photo} alt={data.organization.name} className="img-fluid" /></a>
        </div>

        <Navigation root={root} />
      </div>
    </header>
  );
};

export default Header;
