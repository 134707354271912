export const throttle = (callbackFn, limit) => {
  let wait = false;
  return function() {
    if (!wait) {
      callbackFn.call();
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
};

export const delayCall = (callbackFn, limit) => {
  let timer = null;
  return function handleDelayTimer() {
    if (timer !== null) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      callbackFn();
    }, limit);
  };
};
