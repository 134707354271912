import React, { useEffect, useState, useRef } from 'react';

import AnchorButton from '../anchor-button';

import { delayCall } from '../../utils/time';
import { titles } from '../../state/constants/web-interface';

const DonateButton = () => (
  <AnchorButton className="getstarted" href="https://www.zeffy.com/donation-form/9176d414-2809-4834-adc7-09525bb0e5cb">Donate</AnchorButton>
);

const handleNavigation = (e, root) => {
  const hash = e.currentTarget.hash;

  if (root === true) {
    e.preventDefault();
    window.history.pushState('', '', hash);

    const target = document.querySelector(hash);
    const position = target.offsetTop;
    const header = document.querySelector('#header'); // TODO: find a more react way to get this option
    const headerOffset = header.offsetHeight;

    // when scrolling to a section we have to discount the 
    // header since the header is position outside of the flow of the document
    window.scrollTo({
      top: position - headerOffset,
      behavior: 'smooth'
    });
  }
};

const NavItem = (root, index, target, name, activeItem, setActive, mobileOpen, mobileToggleRef) => {
  const isActive = (!activeItem && index === 0) || (activeItem && activeItem === target);
  return (
    <li key={index}>
      <a
        className={`nav-link scrollto ${isActive ? `active` : ``}`}
        href={`${!root ? `/` : ``}${target}`}
        onClick={ e => {
          if (mobileOpen) {
            mobileToggleRef.current.click();
          }
          setActive(target);
          handleNavigation(e, root); 
        } }
      >{ name }</a>
    </li>
  );
}

const Navigation = ({ root }) => {
  if (root == null) {
    root = false;
  }

  const [ activeItem, setActive ] = useState(null);

  const itemsData = [
    { target: '#hero', name: 'Home' },
    { target: '#about', name: titles.aboutTitle },
    { target: '#impact', name: titles.impactTitle },
    { target: '#participate', name: titles.participateTitle },
    { target: '#what-we-do', name: titles.whatWeDoTitle },
    { target: '#faq', name: titles.faqTitle },
  ];

  
  useEffect(() => {
    // update navigation as the user scrolls over the page
    const updateNavigation = () => {
      const position = window.scrollY + 100;
      itemsData.forEach(item => {
        const section = document.querySelector(item.target);
        if (!section) return;
        if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
          setActive(item.target);
        }
      });
    };

    // This attempts to ONLY update the navigation once the scrolling stops
    const updateNavWithDelay = delayCall(updateNavigation, 150);
    window.addEventListener('scroll', updateNavWithDelay, false);
    return function cleanup() {
      window.removeEventListener('scroll', updateNavWithDelay, false);
    };
  });

  const [mobileOpen, setMobileNav] = useState(false);

  const toggleMobileNav = e => {
    const target = e.currentTarget;
    e.preventDefault();
    
    setMobileNav(!mobileOpen);
    target.classList.toggle('yrl-list');
    target.classList.toggle('yrl-x');
  };

  const mobileToggleRef = useRef();

  return (
    <nav id="navbar" className={`navbar ${mobileOpen ? `navbar-mobile` : ``}`}>
      <DonateButton />
      <ul>
        {itemsData.map((item, index) => NavItem(root, index, item.target, item.name, activeItem, setActive, mobileOpen, mobileToggleRef))}
        <li><DonateButton /></li>
      </ul>
      <i 
        aria-label="mobile nav toggle"
        ref={mobileToggleRef}
        className="yrl yrl-list mobile-nav-toggle"
        onClick={toggleMobileNav}
        onKeyPress={e => { if (e.keyCode === 13) toggleMobileNav(e); } }
        role="button"
        tabIndex={0}></i>
    </nav>
  );
}

export default Navigation;
