export const titles = {
  aboutTitle: `About Us`,
  impactTitle: `Impact`,
  whatWeDoTitle: `What We Do`,
  faqTitle: `F.A.Q.`,
  participateTitle: `Participate`,
};

export const participateForm = {
  name: {
    label: `Your Name`,
    placeholder: `Your Name`,
  },
  email: {
    label: `Your Email`,
    placeholder: `Your Email`,
  },
  subject: {
    label: `Subject`,
    placeholder: `Subject`,
  },
  message: {
    label: `Message`,
    placeholder: ``,
  },
  submit: {
    label: `Send Message`,
  },
  loading: {
    message: `Loading...`,
  },
  thanks: {
    message: `Your message has been sent. Thank you!`,
  },
};

