import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { Email } from 'react-obfuscate-email';

const contactQuery = graphql`
  {
    organization: sanityInfo {
      name
      contact {
        infoEmail {
          email
        }
        phone
      }
      socialNetworks {
        twitter
        facebook
        instagram
        youtube
      }
    }
  }
`;

const Footer = ({ root, theme }) => {
  if (root == null) {
    root = false;
  }

  const data = useStaticQuery(contactQuery);

  return (
    <footer id="footer" className={`${(theme === 'dark' ? `section-bg` : ``)}`}>
      <div className="footer-top">
        <div className="container">
          <div className="row">

            <div className="col-lg-3 col-md-6 footer-contact">
              <h3>You Are Loved</h3>
              <p>
              { data.organization.contact.phone != null &&
                <><strong>Phone:</strong> { data.organization.contact.phone }<br /></>
              }
              { data.organization.contact.infoEmail &&
                <><strong>Email:</strong> <Email email={ data.organization.contact.infoEmail.email } /><br /></>
              }
              </p>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i className="yrl yrl-bx-chevron-right"></i> <a href={`${!root ? `/` : ``}#faq`}>FAQ</a></li>
                {/* <li><i className="yrl yrl-bx-chevron-right"></i> <a href="inner-page.html">Terms of service</a></li> */}
                <li><i className="yrl yrl-bx-chevron-right"></i> <a href="/privacy">Privacy policy</a></li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>About Us</h4>
              <ul>
                <li><i className="yrl yrl-bx-chevron-right"></i> <a href={`${!root ? `/` : ``}#about`}>About us</a></li>
                <li><i className="yrl yrl-bx-chevron-right"></i> <a href={`${!root ? `/` : ``}#what-we-do`}>What we do</a></li>
                <li><i className="yrl yrl-bx-chevron-right"></i> <a href={`${!root ? `/` : ``}#impact`}>Impact</a></li>
              </ul>
            </div>

            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Our Social Networks</h4>
              <p>Check out where else you can find us!</p>
              <div className="social-links mt-3">
              { data.organization.socialNetworks.twitter && 
                <a href={data.organization.socialNetworks.twitter} className="twitter" aria-label="Twitter"><i className="yrl yrl-bxl-twitter"></i></a>
              }
              { data.organization.socialNetworks.facebook && 
                <a href={data.organization.socialNetworks.facebook} className="facebook" aria-label="Facebook"><i className="yrl yrl-bxl-facebook"></i></a>
              }
              { data.organization.socialNetworks.instagram && 
                <a href={data.organization.socialNetworks.instagram} className="instagram" aria-label="Instagram"><i className="yrl yrl-bxl-instagram"></i></a>
              }
              { data.organization.socialNetworks.youtube && 
                <a href={data.organization.socialNetworks.youtube} className="youtube" aria-label="Youtube"><i className="yrl yrl-bxl-youtube"></i></a>
              }
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="container py-4">
        <div className="copyright">
          &copy; Copyright <strong><span>{ data.organization.name }</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
          Built by <a href="https://www.linkedin.com/in/abreumarcos/" target="_blank" rel="noreferrer">Marcos Abreu</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
