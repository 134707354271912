class CloudinaryTransform {
  constructor(cloudinaryURL) {
    // TODO: validate cloudinaryURL
    this.cloudinaryURL = cloudinaryURL;
    this.urlObj = new URL(this.cloudinaryURL);
    this.pathParts = this.urlObj.pathname.split('/');
    this.transform = {};
  }

  format(format) {
    this.transform.format = `f_${format}`;
    return this;
  }

  quality(quality) {
    this.transform.quality = `q_${quality}`;
    return this;
  }

  mode(mode) {
    this.transform.mode = `c_${mode}`;
    return this;
  }

  gravity(gravity) {
    this.transform.mode = `g_${gravity}`;
    return this;
  }

  height(height) {
    this.transform.height = `h_${height}`;
    return this;
  }

  width(width) {
    this.transform.width = `w_${width}`;
    return this;
  }

  toString() {
    const transformString = Object.keys(this.transform).map(key => this.transform[key]).join(',');
    this.pathParts.splice(4, 0, transformString);
    this.urlObj.pathname = this.pathParts.join('/');
    return this.urlObj.toString();
  }
}

export const transform = (cloudinaryURL) => {
  return new CloudinaryTransform(cloudinaryURL);
};

export const profileTransform  = (cloudinaryURL) => {
  const transform = new CloudinaryTransform(cloudinaryURL);
  return transform
    .gravity('faces')
    .mode('fill')
    .format('auto')
    .width('160')
    .height('160')
    .toString();
};

export const eventTransform = (cloudinaryURL) => {
  const transform = new CloudinaryTransform(cloudinaryURL);
  return transform
    .mode('fill')
    .format('auto')
    .width('800')
    .height('600')
    .quality('auto:best')
    .toString();
};

export const eventThumbTransform = (cloudinaryURL) => {
  const transform = new CloudinaryTransform(cloudinaryURL);
  return transform
    .mode('fill')
    .format('auto')
    .width('416')
    .height('312')
    .quality('auto:best')
    .toString();
};

export const participateIconTransform = (cloudinaryURL) => {
  const transform = new CloudinaryTransform(cloudinaryURL);
  return transform
    .mode('fill')
    .width('64')
    .height('48')
    .toString();
};
