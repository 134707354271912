import React, { useEffect, useRef } from 'react';
import { useReadyStateEffect } from 'react-ready-state-effect';

// import Alert from '../alert';
import Header from '../header';
import Footer from '../footer';
// import Meta from '../meta';
import BackToTop from '../back-to-top';

import 'aos/dist/aos.css';
import '../../styles/main.css';


// TODO: extract this method, since we have a similar implementation on navigation
// Scrolls to an element with header offset
const scroolTo = el => {
  const header = document.querySelector('#header');
  const offset = header.offsetHeight;

  let elOffset = document.querySelector(el).offsetTop;
  window.scrollTo({
    top: elOffset - offset,
    behavior: 'smooth',
  });
};

// Scroll with ofset on page load with hash links in the url
const scrollToHash = () => {
  if (window.location.hash) {
    if(document.querySelector(window.location.hash)) {
      scroolTo(window.location.hash);
    }
  }  
};

// 
const lazyLoadImages = () => {
  Array
    .from(document.querySelectorAll(`img[loading="lazy"]`))
    .forEach(img => img.setAttribute(`src`, img.dataset.src));
};

const Layout = ({ root, footerTheme, preview, children }) => {
  // load scroll animation as component mounts
  const refAOS = useRef(null);
  useEffect(() => {
    refAOS.current = require('aos');
    refAOS.current.init({ once: true });
  }, []);
  useEffect(() => {
    if (refAOS) { refAOS.current.refresh(); }
  });

  useReadyStateEffect(() => {
    lazyLoadImages();
  }, [], 'complete');

  useReadyStateEffect(() => {
    if (root) {
      scrollToHash();
    }
  }, [root], 'complete');

  return (
    <div id="top">
      {/* <Meta /> */}
      <Header root={root} />
      {children}
      <Footer root={root} theme={footerTheme} />
      <BackToTop />
    </div>
  );
}

export default Layout;
