import React, { useEffect, useState } from 'react';
import { useReadyStateEffect } from 'react-ready-state-effect';

import { throttle } from '../../utils/time';

const BackToTop = () => {
  const [ isActive, toggleButton ] = useState(false);

  const toggleBackToTop = () => {
    if (window.scrollY > 100) { toggleButton(true); }
    else { toggleButton(false); }
  };

  useEffect(() => {
    const throttledToggleBackToTop = throttle(toggleBackToTop, 200);
    window.addEventListener('scroll', throttledToggleBackToTop);

    return function cleanup() {
      window.removeEventListener('scroll', throttledToggleBackToTop);
    };
  });

  useReadyStateEffect(() => {
    toggleBackToTop();
  }, [], 'complete');

  return (
    <a
      href="#top"
      className={`back-to-top d-flex align-items-center justify-content-center ${isActive ? `active` : ``}`}
    >
      <i className="yrl yrl-arrow-up-short"></i>
    </a>
  );
};

export default BackToTop;
